/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import "@/i18n"
import I18nWrapper from "@/components/I18nWrapper"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import theme from "@/theme"
import "@/components/layout.css"

export const wrapPageElement = ({
  element,
  props: {
    pageContext: { locale },
  },
}) => {
  return (
    <I18nWrapper locale={locale}>
      <MuiThemeProvider theme={theme}>{element}</MuiThemeProvider>
    </I18nWrapper>
  )
}

export const onInitialClientRender = () => {
  document.getElementsByClassName("lds-ellipsis-container")[0].style.display =
    "none"
}
