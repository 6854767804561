import { createMuiTheme } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Helvetica", "Noto Sans TC", "sans-serif"].join(","),
    h3: {
      fontFamily: "Noto Serif TC, serif",
      fontSize: "1.5rem",
      fontWeight: 700,
    },
    h4: {
      fontFamily: "Noto Serif TC, serif",
      fontSize: "1.2rem",
      fontWeight: 700,
    },
    body2: {
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "0.8rem",
      fontWeight: 700,
      color: "black",
    },
  },
  palette: {
    background: {
      default: "#ececec",
      paper: "white",
    },
    primary: {
      main: "#B4B4B4",
      contrastText: "black",
    },
    secondary: {
      main: "#555555",
    },
  },
  breakpoints: {
    values: {
      viewport4: 480,
      viewport7: 768,
      viewport9: 840,
      viewport12: 1200,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
  },
})

export const useBasicStyle = makeStyles(theme => ({
  dotsWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(7.5),
  },
  fullWidthSecondary: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    background: theme.palette.background.paper,
  },
  descriptionWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  defendantCount: {
    marginTop: theme.spacing(2),
    fontSize: "90%",
  },
}))

export const useLandingTabStyle = makeStyles(theme => ({
  legendWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  timeline: {
    display: "flex",
    lineHeight: "1rem",
  },
  timelineDate: {
    position: "relative",
    borderRight: `1px ${theme.palette.primary.main} solid`,
    flexShrink: 0,
    width: theme.spacing(8),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("viewport7")]: {
      paddingRight: theme.spacing(4),
    },
  },
  timelineDot: {
    position: "absolute",
    background: theme.palette.primary.main,
    top: theme.spacing(2.5),
    right: -theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: "50%",
  },
  timelineDotsWrapper: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up("viewport7")]: {
      paddingLeft: theme.spacing(2),
    },
  },
  yearTitle: {
    width: "100%",
    fontWeight: 700,
    fontSize: "2rem",
    lineHeight: "2rem",
    borderBottom: `1px ${theme.palette.primary.main} solid`,
    marginTop: theme.spacing(2),
  },
  defendantsWrapper: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("viewport7")]: {
      marginBottom: theme.spacing(4),
    },
  },
  textField: {
    width: "33vh",
    "&.MuiFormControl-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  textFieldMobile: {
    width: "100%",
    "&.MuiFormControl-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  label: {
    fontSize: "0.875rem",
    fontWeight: 700,
  },
  dotTitle: {
    fontSize: "1.2rem",
    fontFamily: "Noto Serif TC, serif",
    fontWeight: 900,
    display: "inline-flex",
    lineHeight: "1.5rem",
    [theme.breakpoints.up("viewport7")]: {
      fontSize: "1.5rem",
    },
    borderBottom: `3px solid ${theme.palette.background.paper}`,
    "&:hover": {
      textDecoration: "none",
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      transition: `0.3s`,
    },
    "& svg": {
      height: "1.2rem",
      [theme.breakpoints.up("viewport7")]: {
        height: 24,
      },
      fontSize: "inherit",
      marginTop: "0.3rem",
    },
  },
  dotSubTitle: {
    fontSize: "0.875rem",
    [theme.breakpoints.up("viewport7")]: {
      marginTop: "3px",
      fontSize: "1rem",
    },
  },
}))
export default theme
