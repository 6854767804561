// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-charge-js": () => import("./../../../src/templates/Charge.js" /* webpackChunkName: "component---src-templates-charge-js" */),
  "component---src-templates-defendant-js": () => import("./../../../src/templates/Defendant.js" /* webpackChunkName: "component---src-templates-defendant-js" */),
  "component---src-templates-incident-js": () => import("./../../../src/templates/Incident.js" /* webpackChunkName: "component---src-templates-incident-js" */)
}

